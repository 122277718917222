import { ConsentManagerBuilder } from '@segment/consent-manager'
import {
  CategoryPreferences,
  Destination,
} from '@segment/consent-manager/types/types'
import React, { useState } from 'react'

import ContentfulRichText from '../_elements/ContentfulRichText'
import CTAButton from '../_elements/_buttons/CTAButton'

import { Header, Row } from './Elements'
import { ADVERTISING_CATEGORIES, FUNCTIONAL_CATEGORIES } from './categories'

import styles from './cookie-banner.module.scss'
import { tracking } from '../_functional/Segment/tracking'
export const CookieBannerBlockTypename = 'ContentfulBlockCookieBanner'

export interface CookieContentProps {
  bodyText: { json: any } | null | undefined
  buttonAcceptLabel: string | null | undefined
  buttonAcceptSelectedLabel: string | null | undefined
  buttonRejectLabel: string | null | undefined
  buttonDetailsLabel: string | null | undefined
  title: string | null | undefined
  purposeTextFunctionalCookies: { json: any } | null | undefined
  purposeExampleFunctionalCookies: { json: any } | null | undefined
  functionalCategoryName: string | null | undefined
  purposeTextMarketingCookies: { json: any } | null | undefined
  purposeExampleMarketingCookies: { json: any } | null | undefined
  marketingCategoryName: string | null | undefined
  purposeTextAdvertising: { json: any } | null | undefined
  purposeExampleAdvertising: { json: any } | null | undefined
  advertisingCategoryName: string | null | undefined
  purposeTextEssential: { json: any } | null | undefined
  purposeExampleEssential: { json: any } | null | undefined
  essentialCategoryName: string | null | undefined
  columnHeaderAllow: string | null | undefined
  columnHeaderCategory: string | null | undefined
  columnHeaderPurpose: string | null | undefined
  columnHeaderTools: string | null | undefined
  yesText: string | null | undefined
  noText: string | null | undefined
  __typename?: string | null | undefined
}
export interface RowContentProps {
  name: string
  essential: boolean
  preferenceCategory: any
  rowHeader: string | null | undefined
  purposeText: { json: any } | null | undefined
  purposeExample: { json: any } | null | undefined
  destinations: any
}
export interface CookieBannerProps {
  __typename?: typeof CookieBannerBlockTypename
  showBanner: boolean
  toggleBanner: React.Dispatch<React.SetStateAction<boolean>>
  isBigBannerVisible: boolean
  setIsBigBannerVisible: React.Dispatch<React.SetStateAction<boolean>>
  content: CookieContentProps | null | undefined
}
export default function CookieBanner({
  showBanner,
  toggleBanner,
  content,
  isBigBannerVisible,
  setIsBigBannerVisible,
}: CookieBannerProps) {
  const consentManagerWriteKey = process.env.GATSBY_SEGMENT_API_KEY
  const [acceptButton, setAcceptButton] = useState<string | null | undefined>(
    content?.buttonAcceptLabel
  )

  if (!consentManagerWriteKey) {
    return null
  }

  function normalizeDestinations(destinations: Destination[]) {
    const marketingDestinations: Destination[] = []
    const advertisingDestinations: Destination[] = []
    const functionalDestinations: Destination[] = []

    for (const destination of destinations) {
      if (
        ADVERTISING_CATEGORIES.find((c: string) => c === destination.category)
      ) {
        advertisingDestinations.push(destination)
      } else if (
        FUNCTIONAL_CATEGORIES.find((c: string) => c === destination.category)
      ) {
        functionalDestinations.push(destination)
      } else {
        // Fallback to marketing
        marketingDestinations.push(destination)
      }
    }

    return {
      marketingDestinations,
      advertisingDestinations,
      functionalDestinations,
    }
  }
  const handleMapCustomPreferences = (
    destinations: Destination[],
    preferences: CategoryPreferences
  ) => {
    const destinationPreferences: CategoryPreferences = {}
    const customPreferences: CategoryPreferences = {}

    // Default unset preferences to true (for implicit consent)
    for (const preferenceName of Object.keys(preferences)) {
      const value = preferences[preferenceName]
      if (typeof value === 'boolean') {
        customPreferences[preferenceName] = value
      } else {
        customPreferences[preferenceName] = true
      }
    }

    const customPrefs = customPreferences as CategoryPreferences

    for (const destination of destinations) {
      // Mark advertising destinations
      if (
        ADVERTISING_CATEGORIES.find((c) => c === destination.category) &&
        destinationPreferences[destination.id] !== false
      ) {
        destinationPreferences[destination.id] = customPrefs.advertising
      }

      // Mark function destinations
      if (
        FUNCTIONAL_CATEGORIES.find((c) => c === destination.category) &&
        destinationPreferences[destination.id] !== false
      ) {
        destinationPreferences[destination.id] = customPrefs.functional
      }

      // Fallback to marketing
      if (!(destination.id in destinationPreferences)) {
        destinationPreferences[destination.id] =
          customPrefs.marketingAndAnalytics
      }
    }

    return { destinationPreferences, customPreferences }
  }

  return (
    <div
      className={`${styles.background} ${
        showBanner ? styles.showBanner : styles.hideBanner
      }`}
      id="background"
    >
      <div className={styles.cookie}>
        <ConsentManagerBuilder
          shouldReload={false}
          writeKey={consentManagerWriteKey}
          shouldRequireConsent={() => true}
          mapCustomPreferences={handleMapCustomPreferences}
        >
          {({ destinations, preferences, setPreferences, saveConsent }) => {
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              const target = e.target
              const value = target.checked
              const category = target.name
              setPreferences({ [category]: value })
              setAcceptButton(content?.buttonAcceptSelectedLabel)
            }

            function onAcceptButtonClick() {
              setPreferences({
                advertising: true,
                functional: true,
                marketingAndAnalytics: true,
              })
              tracking.updateConsentPreferences(true, true).then(() => {
                setTimeout(() => {
                  saveConsent(undefined, false)
                  toggleBanner(false)
                }, 100)
              }).catch(() => {
                setTimeout(() => {
                  saveConsent(undefined, false)
                  toggleBanner(false)
                }, 100)
              });

            }
            function onAcceptSelectedClick() {

              saveConsent(undefined, false)
              tracking.updateConsentPreferences(preferences.marketingAndAnalytics === true, preferences.advertising === true).then(() => {
              setTimeout(() => {
                toggleBanner(false)
              }, 100)}).catch(() => {
                setTimeout(() => {
                  toggleBanner(false)
                }, 100)
              });
            }
            function onAcceptAnyClick() {
              if (
                preferences.advertising === undefined &&
                preferences.functional === undefined &&
                preferences.marketingAndAnalytics === undefined
              ) {
                onAcceptButtonClick()
              } else if (
                preferences.advertising !== undefined ||
                preferences.functional !== undefined ||
                preferences.marketingAndAnalytics !== undefined
              ) {
                onAcceptSelectedClick()
              }
            }

            function onRejectButtonClick() {
              saveConsent(false, false);
              tracking.updateConsentPreferences(false, false).then(() => {
                setTimeout(() => {
                toggleBanner(false)
              }, 100)}).catch(() => {
                setTimeout(() => {
                  toggleBanner(false)
                }, 100)
              });


            }

            const {
              marketingDestinations,
              advertisingDestinations,
              functionalDestinations,
            } = normalizeDestinations(destinations)

            const rowContent: Array<RowContentProps> = [
              {
                name: 'functional',
                essential: false,
                preferenceCategory: preferences?.functional,
                rowHeader: content?.functionalCategoryName,
                purposeText: content?.purposeTextFunctionalCookies,
                purposeExample: content?.purposeExampleFunctionalCookies,
                destinations: functionalDestinations,
              },
              {
                name: 'marketingAndAnalytics',
                essential: false,
                preferenceCategory: preferences?.marketingAndAnalytics,
                rowHeader: content?.marketingCategoryName,
                purposeText: content?.purposeTextMarketingCookies,
                purposeExample: content?.purposeExampleMarketingCookies,
                destinations: marketingDestinations,
              },
              {
                name: 'advertising',
                essential: false,
                preferenceCategory: preferences?.advertising,
                rowHeader: content?.advertisingCategoryName,
                purposeText: content?.purposeTextAdvertising,
                purposeExample: content?.purposeExampleAdvertising,
                destinations: advertisingDestinations,
              },
              {
                name: 'essential',
                essential: true,
                preferenceCategory: true,
                rowHeader: content?.essentialCategoryName,
                purposeText: content?.purposeTextEssential,
                purposeExample: content?.purposeExampleEssential,
                destinations: [],
              },
            ]
            //checks if isBigBannerVisible depending or true or false the small or big banner will load
            return (
              <div>
                {isBigBannerVisible ? (
                  <div className={styles.container}>
                    <h1 className={styles.title}>
                      {content?.title ?? 'We use cookies'}
                    </h1>
                    <div className={styles.text}>
                      {content?.bodyText && (
                        <ContentfulRichText json={content?.bodyText?.json} />
                      )}
                    </div>
                    <table className={styles.table}>
                      <thead>
                        <Header
                          allowValue={content?.columnHeaderAllow}
                          categoryValue={content?.columnHeaderCategory}
                          purposeValue={content?.columnHeaderPurpose}
                          toolsValue={content?.columnHeaderTools}
                        />
                      </thead>
                      <tbody>
                        {rowContent.map(
                          ({
                            name,
                            essential,
                            preferenceCategory,
                            rowHeader,
                            purposeText,
                            purposeExample,
                            destinations,
                          }) => (
                            <Row
                              key={name}
                              name={name}
                              essential={essential}
                              preferenceCategory={preferenceCategory}
                              handleChange={handleChange}
                              rowHeader={rowHeader}
                              purposeText={purposeText}
                              purposeExample={purposeExample}
                              yesText={content?.yesText}
                              noText={content?.noText}
                              destinations={destinations}
                            />
                          )
                        )}
                      </tbody>
                    </table>
                    <div className={styles.buttonGroup}>
                      <CTAButton
                        className={styles.acceptButton}
                        isSmaller
                        onClick={onAcceptAnyClick}
                        withShadow={false}
                      >
                        {acceptButton ?? 'Accept all'}
                      </CTAButton>
                      <CTAButton
                        className={styles.rejectButton}
                        isSmaller
                        onClick={onRejectButtonClick}
                        withShadow={false}
                      >
                        {content?.buttonRejectLabel ?? 'Only the Necessary'}
                      </CTAButton>
                    </div>
                  </div>
                ) : (
                  <div className={styles.container}>
                    <h1 className={styles.title}>
                      {content?.title ?? 'We use cookies'}
                    </h1>
                    {content?.bodyText && (
                      <ContentfulRichText json={content?.bodyText?.json} />
                    )}

                    <div className={styles.buttonGroup}>
                      <CTAButton
                        className={styles.acceptButton}
                        isSmaller
                        onClick={onAcceptButtonClick}
                        withShadow={false}
                      >
                        {acceptButton ?? 'Accept all'}
                      </CTAButton>{' '}
                      <CTAButton
                        className={styles.rejectButton}
                        isSmaller
                        onClick={onRejectButtonClick}
                        withShadow={false}
                      >
                        {content?.buttonRejectLabel ?? 'Only the Necessary'}
                      </CTAButton>{' '}
                      <CTAButton
                        className={styles.rejectButton}
                        isSmaller
                        onClick={() => setIsBigBannerVisible(true)}
                        withShadow={false}
                      >
                        {content?.buttonDetailsLabel ?? 'Show Details'}
                      </CTAButton>
                    </div>
                  </div>
                )}
              </div>
            )
          }}
        </ConsentManagerBuilder>
      </div>
    </div>
  )
}
